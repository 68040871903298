<template>
  <div
    class="header"
    style="background-color: white; padding-bottom: 0px; width: 100%"
  >
    <v-row
      class="title-row"
      style="padding-top: 8px; min-height: 80px; height: unset"
    >
      <v-col style="gap: 4px; display: grid">
        <h4
          :key="nameUpdater"
          v-if="project.name"
          id="project-title"
          class="header-title"
        >
          {{ project.name }}
        </h4>
        <div class="broker-info">
          <img src="@/assets/images/users.svg" alt="" />
          <span
            v-for="(creator, userKey) in project.access.users"
            :key="userKey"
            class="button-small hoverable-info"
            style="margin-left: -2px"
            @click="(event) => openBrokerDetailPanel(event, userKey)"
          >
            {{ creator.name }}
            {{
              creator.ownershipType === "owner"
                ? "(" + $t("Owner").toLowerCase() + ")"
                : ""
            }}
          </span>
          <button
            v-if="user.isAdmin || project.access.users[user._id]"
            class="animated-button"
            @click="openAddUsers()"
          >
            <p style="font-size: 16px; margin-top: -1px">+</p>
            <span>{{ $t("ManageUsers") }}</span>
          </button>
        </div>
        <span
          style="
            background: #fff0f0;
            border-radius: 4px;
            display: flex;
            justify-content: center;
            max-width: 296px;
          "
          v-if="projectStatuscode === 0"
        >
          <p class="content-bold" style="color: #ff1e24">
            {{ $t("DeletedProject") }}
          </p>
        </span>
        <span v-if="openHouse">
          <OpenHouseContainer :task="openHouse" />
        </span>
      </v-col>

      <!--      {{ firstOpenHouse }}-->
    </v-row>
    <!--  <v-row
      style="width: 100%; height: 42px; margin-top: 0; padding-left: 104px"
    > -->
    <!-- <div
        v-bind:class="{
          'active-view': activeView === 'generalView',
          '': activeView !== 'generalView',
        }"
        @click="changeView('generalView')"
        id="generalView"
      >
        <p class="content-normal view-link">{{ $t("General") }}</p>
      </div>
      <div
        v-bind:class="{
          'active-view': activeView === 'unitsView',
          '': activeView !== 'unitsView',
        }"
        @click="changeView('unitsView')"
        id="unitsView"
      >
        <p class="content-normal view-link">{{ $t("Units") }}</p>
      </div>
      <div
        v-bind:class="{
          'active-view': activeView === 'listingsView',
          '': activeView !== 'listingsView',
        }"
        @click="changeView('listingsView')"
        id="listingsView"
      >
        <p class="content-normal view-link">{{ $t("Listings") }}</p>
      </div>
      <div
        v-bind:class="{
          'active-view': activeView === 'leadsView',
          '': activeView !== 'leadsView',
        }"
        @click="changeView('leadsView')"
        id="leadsView"
      >
        <p class="content-normal view-link">{{ $t("BoardsView") }}</p>
      </div> -->
    <!--  </v-row> -->
    <BrokerInfoPanel
      v-if="brokerDetailOpened"
      :item="clickedBroker"
      @closed="closeBrokerDetailPanel"
      :position="brokerPanelPosition"
    />
    <AddBrokerModal
      v-if="isAddBrokerOpen"
      :item.sync="project"
      @close-modal="isAddBrokerOpen = false"
      :project-modal="true"
      @updated="getProject(project._id)"
      @transferProjectOwnership="
        isAddBrokerOpen = false;
        transferData = $event;
        transferConfirmationOpen = true;
      "
    />
    <ConfirmationModal
      v-if="transferConfirmationOpen"
      @onCancel="
        transferConfirmationOpen = false;
        isAddBrokerOpen = true;
      "
      @onConfirm="transferProject()"
    >
      <div class="confirmation-wrapper">
        <div class="confirmation-header">
          <img
            style="margin-right: 8px"
            :src="require(`@/assets/images/info.svg`)"
            alt=""
          />
          <div>
            {{ $t("TransferingOwner") }}
          </div>
        </div>
        <div>
          <div>
            <BaseToggle
              :label="$t('ChangeOwnerOfProjectListings')"
              v-model="transferListings"
              type="number"
            />
          </div>
        </div>
      </div>
    </ConfirmationModal>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import OpenHouseContainer from "@/components/common/OpenHouseContainer";
import BrokerInfoPanel from "@/components/common/BrokerInfoPanel";
import AddBrokerModal from "@/components/common/AddBrokerModal";
import ConfirmationModal from "@/components/common/ConfirmationModal.vue";
import BaseToggle from "@/components/common/BaseToggle.vue";
import axios from "axios";

export default {
  name: "Calendar",
  components: {
    BaseToggle,
    ConfirmationModal,
    OpenHouseContainer,
    BrokerInfoPanel,
    AddBrokerModal,
  },
  props: ["openHouse", "projectStatuscode", "project"],
  data() {
    return {
      activeView: "generalView",
      nameUpdater: 0,
      clickedBroker: null,
      brokerDetailOpened: false,
      isAddBrokerOpen: false,
      transferConfirmationOpen: false,
      transferListings: true,
      transferData: null,
    };
  },
  created() {
    this.selectTab();
  },
  mounted() {},
  watch: {
    "$route.params.id": async function () {
      this.nameUpdater++;
    },
  },
  computed: {},
  methods: {
    ...mapActions([
      "changeSidepanelStateDeals",
      "changeSidepanelStateDeal",
      "getProject",
    ]),
    selectTab() {
      switch (this.$route.name) {
        case "ProjectGeneral":
          this.activeView = "generalView";
          this.closeLeads();
          break;
        case "ProjectListings":
          this.activeView = "listingsView";
          this.closeLeads();
          break;
        case "ProjectUnits":
          this.activeView = "unitsView";
          this.closeLeads();
          break;
        case "ProjectLeads":
          this.activeView = "leadsView";
          break;
      }
    },
    openBrokerDetailPanel(event, person) {
      this.brokerPanelPosition = {
        left: event.clientX + "px",
        top: event.clientY - 60 + "px",
      };
      this.brokerDetailOpened = true;
      this.clickedBroker = person;
    },
    closeBrokerDetailPanel() {
      this.brokerDetailOpened = false;
    },
    async transferProject() {
      let body = this.transferData;
      body.transferListings = this.transferListings;
      await axios.post("/api/project/ownership/change", body);
      this.transferData = null;
      location.reload();
    },
    openAddUsers() {
      this.isAddBrokerOpen = true;
    },
    changeView(to) {
      switch (to) {
        case "generalView":
          this.activeView = "generalView";
          this.closeLeads();
          this.$router.push({ name: "ProjectGeneral" });
          break;
        case "listingsView":
          this.activeView = "listingsView";
          this.closeLeads();
          this.$router.push({ name: "ProjectListings" });
          break;
        case "unitsView":
          this.activeView = "unitsView";
          this.closeLeads();
          this.$router.push({ name: "ProjectUnits" });
          break;
        case "leadsView":
          this.activeView = "leadsView";
          this.closeLeads();
          this.$router.push({ name: "ProjectLeads" });
      }
    },
    closeLeads() {
      this.isLeadsOpen = !this.isLeadsOpen;
      let listingHeader = document.getElementById("listing-header");
      let listingContent = document.getElementById("listing-content");
      let sidepanelLeads = document.getElementById("sidepanel-leads");
      if (sidepanelLeads) {
        listingHeader.classList.remove("slide-animation-open");
        listingContent.classList.remove("slide-animation-open");
        sidepanelLeads.classList.remove("slide-open");
        listingHeader.classList.add("slide-animation-close");
        listingContent.classList.add("slide-animation-close");
        sidepanelLeads.classList.add("slide-close");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/global_variables";

.v-list-item {
  .v-list-item--link {
    .theme--light div {
      display: flex;
    }
  }
}

.col {
  padding: 0;
}

.left-row {
  justify-content: flex-start;

  .v-select {
    max-width: 250px;
  }

  .sector-type {
    margin-right: 42px;
  }
}

.right-row {
  justify-content: flex-end;

  .autocomplete {
    max-width: 300px;
  }

  .icon-button {
    border: 1px solid #e4e4e4;
    height: 40px;
    width: 40px;
    display: flex;
    outline: none;
    padding: 16px;
    align-items: center;
    user-select: none;
    margin-right: 16px;
    margin-left: 16px;
    border-radius: 8px;

    justify-content: center;
  }
}

.icon-button-active {
  background-color: #f4f5f7;
}

#generalView {
  padding-bottom: 22px;
}

#listingsView,
#unitsView {
  margin-left: 24px;
  padding-bottom: 22px;
}

#leadsView {
  margin-left: 24px;
  padding-bottom: 22px;
}

#listingsView,
#generalView,
#unitsView,
#leadsView {
  cursor: pointer;
}

.active-view {
  border-bottom: 1px solid black;
}

.broker-info {
  flex-direction: row;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 0px;
}

.hoverable-info {
  cursor: pointer;
  background-color: #f4f5f7;
  padding: 2px 4px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #ff5c01;
    color: #ffffff;
    font-weight: bold;
  }
}

.animated-button {
  border: 1px solid $grey500;
  border-radius: 6px;
  height: 20px;
  min-width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 4px;
  font-size: 12px;

  &:hover {
    color: #ffffff;
    background-color: #000000;
    border: 1px solid #000000;
  }
}

.animated-button span {
  max-width: 0;
  -webkit-transition: max-width 0.1s;
  transition: max-width 0.1s;
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
  overflow: hidden;
  color: #ffffff;
}

.animated-button:hover span {
  padding: 0px 2px;
  max-width: 7rem;
}

.animated-button:hover p {
  color: #ffffff;
}
.confirmation-wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.confirmation-header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
</style>
